<template lang="">
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="2">
        <el-card class="box-card">
          <p class="text-2xl border-b-2 pb-2 mb-3">Custom Reservation Form</p>
          <el-form label-width="auto" label-position="top" size="large">
            <el-form-item label="Cities">
              <el-select-v2
                v-model="form.city"
                :options="options"
                placeholder="Please select city/cities that you planned to tour  "
                style="width: 100%"
                allow-create
                filterable
                multiple
                clearable
              />
            </el-form-item>
            <el-form-item label="Full Name">
              <el-input
                v-model="form.fullName"
                placeholder="Please enter your name"
              />
            </el-form-item>
            <el-form-item label="E Mail">
              <el-input
                v-model="form.eMail"
                placeholder="Please enter your email"
              />
            </el-form-item>
            <MazPhoneNumberInput
              size="sm"
              countriesHeight="20"
              class="mb-2"
              v-model="form.phone.phoneNumber"
              @update="form.phone.results = $event"
            />
            <el-form-item label="Where will you come from?">
              <el-input
                v-model="form.country"
                type="text"
                placeholder="Please enter your country"
              />
            </el-form-item>
            <el-form-item label="Schedule">
              <el-date-picker
                v-model="form.departureDate"
                type="date"
                placeholder="Departure Date"
                style="width: 100%"
                class="mb-2"
              ></el-date-picker>
              <el-date-picker
                v-model="form.arrivalDate"
                type="date"
                placeholder="Arrival Date"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="Group Size">
              <el-col :span="11">
                <el-form-item label="Adult(s)">
                  <el-input-number
                    :min="1"
                    v-model="form.groupSize.adult"
                    size="large"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="Child(s)">
                  <el-input-number
                    :min="0"
                    v-model="form.groupSize.child"
                    size="large"
                  />
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="GDPR Consent">
              <el-checkbox v-model="form.gdpr" label="" size="large" border
                >Agree on our terms and condition for <br />
                using your submitted data?</el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-alert
                title="Please be sure that your information correct"
                type="warning"
                center
                show-icon
                :closable="false"
              />
            </el-form-item>
          </el-form>

          <el-button
            class="pt-4 submit-btn"
            size="large"
            type="success"
            @click="submitForm()"
            >Send Your Request</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {},
  data() {
    return {
      form: {
        fullName: "",
        phone: {
          phoneNumber: null,
          results: null,
        },
        eMail: "",
        arrivalDate: "",
        departureDate: "",
        city: "",
        country: "",
        groupSize: {
          adult: "",
          child: "",
        },
      },
      options: [
        {
          value: "istanbul",
          label: "Istanbul",
        },
        {
          value: "ephesus",
          label: "Ephesus",
        },
        {
          value: "pamukkale",
          label: "Pamukkale",
        },
        {
          value: "gallipoli",
          label: "Gallipoli",
        },
        {
          value: "troy",
          label: "Troy",
        },
        {
          value: "antalya",
          label: "Antalya",
        },
        {
          value: "fethiye",
          label: "Fethiye",
        },
        {
          value: "konya",
          label: "Konya",
        },
        {
          value: "cappadocia",
          label: "Cappadocia",
        },
        {
          value: "pergamon",
          label: "Pergamon",
        },
      ],
    };
  },
  methods: {
    submitForm() {
      console.log(this.form);
      const config = {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded);
          Swal.fire({
            title: "Processing",
            text: 'Please wait...',
            icon: "info",
          });
        },
      };
      axios
        .post("https://api.truebluetour.com/api/quick-reservation-form/submit", this.form,config)
        .then((res) => {
          Swal.fire({
            title: "Message",
            text: res.data.ticketId,
            icon: "success",
            confirmButtonText: "Ok",
          });
          setTimeout(function () {
            window.location.replace("./thank-you/" + res.data.ticketId);
          }, 3000);
        });
    },
  },
};
</script>
<style lang="css">
.submit-btn{
  background-color: #67c23a !important;
}
</style>
